import { useQuery } from "@tanstack/react-query";
import { __financesApiClient } from "../rest-api/client-instances-application-scoped";


export const useQueryUserBankAccounts = () => { 
  return useQuery({
    queryKey: ['userBankAccounts'],
    queryFn: async () => __financesApiClient.getUserBankAccounts(),
    staleTime: 1,
  });
};