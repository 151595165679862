
export const DEFAULT_LOCALE = 'es-CO';
export const DEFAULT_CURRENCY = 'COP';

/**
 * When called with `.format(125300)`,
 * the output would be: `-$1.25M`
 * 
 * @param locale Default: `es-CO`
 * @param currency Default: `COP`
 * @returns Currency Number Formatter. 
 */
export const getCurrencyCompactFormatter = (locale: string = DEFAULT_LOCALE, currency: string = DEFAULT_CURRENCY) => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    currencyDisplay: 'narrowSymbol',
    maximumFractionDigits: 2,
    notation: 'compact',
    minimumFractionDigits: 0
  });
};

/**
 * When called with `.format(-1250800)`, 
 * the output would be: `-$ 1.250.800,00`
 * 
 * @param locale Default: `es-CO`
 * @param currency Default: `COP`
 * @returns Currency Number Formatter. 
 */
export const getCurrencyVerboseFormatter = (locale: string = DEFAULT_LOCALE, currency: string = DEFAULT_CURRENCY) => {
  return new Intl.NumberFormat('es-CO', {
    style: 'currency',
    currency,
    currencyDisplay: 'symbol',
    maximumFractionDigits: 0,
  });
};