import { QueryClient, useMutation, useQuery } from "@tanstack/react-query";
import { __financesApiClient } from "../rest-api/client-instances-application-scoped";
import { UserPayIn } from "../types/User";
import { StringOrNumber } from "../types/global";

export const useQueryUserPayIns = (dateRange: [StringOrNumber, StringOrNumber]) => {
  return useQuery({
    queryKey: ['userPayIns', dateRange],
    queryFn: async () => __financesApiClient.getUserPayIns(dateRange),
    staleTime: 1,
  });
};

export const useMutationCreatePayIn = (queryClient: QueryClient) => {
  return useMutation({
    mutationFn: async (payIn: UserPayIn) => __financesApiClient.createPayIn(payIn),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['userPayIns'] });
      queryClient.invalidateQueries({queryKey: ['userBankAccounts']});
    }
  });
};


export const useMutationDeleteUserPayIns = (queryClient: QueryClient) => {
  return useMutation({
    mutationFn: async (createdAtEpoch: number) => __financesApiClient.deletePayIn(createdAtEpoch),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['userPayIns'] });
      queryClient.invalidateQueries({ queryKey: ['userBankAccounts'] });
    },
  });
};