import { Button, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid/Grid";
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { changeDateRange } from "../redux/state-slices/globalSlice";


dayjs.extend(timezone);
dayjs.tz.setDefault("America/Bogota");
type DateOptions = {
  [label: string]: [string, string];
};

export default function DateRangePicker() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();

  const now = dayjs();
  const nowMs = now.toISOString();

  const [selectedOption, setSelectedOption] = useState<string>('last15Days');

  const options: DateOptions = {
    'today': [now.startOf('day').toISOString(), nowMs],
    'last7Days': [now.subtract(7, 'days').toISOString(), nowMs],
    'last15Days': [now.subtract(15, 'days').toISOString(), nowMs],
    'last30Days': [now.subtract(30, 'days').toISOString(), nowMs],
    'thisWeek': [now.startOf('week').toISOString(), nowMs],
    'thisMonth': [now.startOf('month').toISOString(), nowMs],
    'lastMonth': [now.subtract(1, 'month').startOf('month').toISOString(), now.subtract(1, 'month').endOf('month').toISOString()],
    'last3Months': [now.subtract(3, 'months').startOf('month').toISOString(), nowMs],
    'last6Months': [now.subtract(6, 'months').startOf('month').toISOString(), nowMs],
    'thisYear': [now.startOf('year').toISOString(), nowMs],
    'lastYear': [now.startOf('year').subtract(1, 'year').toISOString(), now.startOf('year').subtract(1,'second').toISOString()],
  };

  return (
    <Grid container justifyContent="center"  sx={{ backgroundColor: theme.palette.grey[100], position: 'sticky', top: 0, zIndex: 10 }}>
      {Object.keys(options).map(optionKey => (
        <Grid item key={optionKey}>
          <Button  variant={selectedOption === optionKey ? 'contained' : 'text'} color="secondary"
            onClick={() => {
              dispatch(changeDateRange(options[optionKey]));
              setSelectedOption(optionKey);
            }}
          >
            {t(optionKey)}
          </Button>
        </Grid>
      ))}
    </Grid>
  );
}