import { createSlice } from "@reduxjs/toolkit";
import { StringOrNumber } from "../../types/global";
import { __financesApiClient } from "../../rest-api/client-instances-application-scoped";

export type GlobalState = {
  global: {
    userId: string | undefined;
    userJwt: string;
    showCreateExpenseForm: boolean;
    showCreatePayInForm: boolean;
    isFABOpen: boolean;
    dateRange: [StringOrNumber, StringOrNumber],
  }
};

const DAYS_15 = 1000 * 60 * 60 * 24 * 15;
export const globalSlice = createSlice({
  name: 'global',
  initialState: {
    userId: null,
    userJwt: null,
    showCreateExpenseForm: false,
    showCreatePayInForm: false,
    isFABOpen: false,
    dateRange: [
      new Date(Date.now() - DAYS_15).toISOString(),
      new Date().toISOString()
    ],
  },
  reducers: {
    setUserId: (state, action) => {
      state.userId = action.payload;
      __financesApiClient.setUserId(action.payload);
    },
    setUserJwt: (stage, action) => {
      stage.userJwt = action.payload;
      __financesApiClient.setUserJwt(action.payload);
    },
    toggleShowCreateExpenseForm: (state, action) => {
      state.showCreateExpenseForm = action.payload ?? !state.showCreateExpenseForm;
    },
    toggleShowCreatePayInForm: (state, action) => {
      state.showCreatePayInForm = action.payload ?? !state.showCreatePayInForm;
    },
    toggleFAB: (state, action) => {
      state.isFABOpen = action.payload ?? !state.isFABOpen;
    },
    changeDateRange: (state, action) => {
      state.dateRange = action.payload ?? [];
    }
  }
});

export const {
  toggleFAB,
  toggleShowCreateExpenseForm,
  toggleShowCreatePayInForm,
  changeDateRange,
  setUserId,
  setUserJwt,

} = globalSlice.actions;

export default globalSlice.reducer;