import { ThemeOptions } from "@mui/material";

export const dark = {
  palette: {
    mode: 'dark',
    grey: {
      "100": '#121212'
    },
    text: {
      primary: '#eee',
      disabled: '#ccc'
    },
    primary: {
      main: '#006D77',
      contrastText: '#eee'
    },
    secondary: {
      main: '#E29578',
      contrastText: '#eee',
    },
  },
  typography: {
    allVariants: { fontFamily: 'monospace' },
    h6: {
      fontFamily: 'fantasy',
      letterSpacing: '2px'
    }
  }
} as ThemeOptions;

export const light = {
  palette: {
    mode: 'light',
    background: {
      default: 'rgb(245,245,245)',
    },
    grey: {
      "100": 'rgb(235,235,235)'
    },
    text: {
      primary: '#000',
      disabled: '#eee'
    },
    primary: {
      main: '#006D77',
      contrastText: '#333'
    },
    secondary: {
      main: '#E29578',
      contrastText: '#fff',
    },
  },
  typography: {
    allVariants: { fontFamily: 'monospace' },
    h6: {
      fontFamily: 'fantasy',
      letterSpacing: '2px'
    }
  }
} as ThemeOptions;