import { Autocomplete, TextField } from "@mui/material";
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { FC } from "react";

export type TextFieldHintProps = {
  label: string;
  options: string[];
  required?: boolean;
  inputValue: string;
  setInputValue: Function;
};
const TextFieldHint: FC<TextFieldHintProps> = (props) => {
  const { label, options, required, inputValue, setInputValue } = props;

  return (
    <Autocomplete
      sx={{ width: 'inherit' }}
      options={options}
      getOptionLabel={(option) => option}
      freeSolo={true}
      renderInput={(params) => {
        return (
          <TextField {...params} value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onBlur={(e) => setInputValue(e.target.value)}
            label={label} margin="normal" required={required} />
        )
      }}
      renderOption={(props, option, { inputValue }) => {
        const matches = match(option, inputValue, { insideWords: true });
        const parts = parse(option, matches);

        return (
          <li {...props}>
            <div>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{
                    fontWeight: part.highlight ? 700 : 400,
                    color: part.highlight ? 'royalblue' : 'inherit'
                  }}
                >
                  {part.text}
                </span>
              ))}
            </div>
          </li>
        );
      }}
    />
  );
};

export default TextFieldHint;
