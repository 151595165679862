import { Container, Grid, Paper, Typography } from '@mui/material';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ExpensesAndPayInsChartDataByDate, UserExpense, UserPayIn } from '../types/User';
import ExpenseTimeline from './ExpenseTimeline';
import { BarChartByBankAccount } from './metrics/BarChartByBankAccount';
import BarChartByExpenseName from './metrics/BarChartByExpenseName';
import { ChartByPayIns } from './metrics/ChartByPayIns';
import PayInsTimeline from './PayInsTimeline';

dayjs.extend(timezone);
dayjs.tz.setDefault("America/Bogota");

export type DashboardProps = {
  data: {
    metrics: Record<any, any>;
    userExpenses: UserExpense[];
  };
  userPayIns?: UserPayIn[];
};


const UserExpenseMetrics: FC<DashboardProps> = (props) => {
  const { data, userPayIns } = props;

  const { t } = useTranslation();
  const { metrics, userExpenses, } = data;
  const { byExpenseName, byBankAccount, } = metrics;
  const paperGridWidthRefForBarChartByExpenseName = useRef<HTMLDivElement>();

  const dayMapExpensesAndPayIns: ExpensesAndPayInsChartDataByDate = {};

  userExpenses.forEach(expense => {
    const date = dayjs(expense.createDate).format('YYYY-MM-DD');
    const dateObj = dayMapExpensesAndPayIns[date] ?? { expenseAmount: 0, payInAmount: 0, date, expensesCount: 0, payInsCount: 0 };
    dateObj.expenseAmount += expense.amount;
    dateObj.expensesCount += 1;
    dayMapExpensesAndPayIns[date] = dateObj;
  });

  (userPayIns ?? []).forEach(payIn => {
    const date = dayjs(payIn.createDate).format('YYYY-MM-DD');
    const dateObj = dayMapExpensesAndPayIns[date] ?? { expenseAmount: 0, payInAmount: 0, date, expensesCount: 0, payInsCount: 0 };
    dateObj.payInAmount += payIn.amount;
    dateObj.payInsCount += 1;
    dayMapExpensesAndPayIns[date] = dateObj;
  });

  const chartByPayInsData = Object.values(dayMapExpensesAndPayIns);

  return (
    <Container sx={{ mt: 0, pb: 20, mb: 10 }} maxWidth={false}>
      <Grid container spacing={2}>

        <Grid item xs={12} md={3}>

          <Paper elevation={2} sx={{ p: 2, textAlign: 'center', minHeight: 400, maxHeight: 1500, overflow: 'auto', scrollbarWidth: 'thin' }}>
            <Typography variant="h6" gutterBottom>
              {t('expensesTimeline')}
            </Typography>

            <ExpenseTimeline userExpenses={userExpenses as Required<UserExpense>[]} />
          </Paper>

          <Paper elevation={2} sx={{ p: 2, textAlign: 'center', mt: 1, minHeight: 400, maxHeight: 1500, overflow: 'auto', scrollbarWidth: 'thin' }}>
            <Typography variant="h6" gutterBottom>
              {t('payInsTimeline')}
            </Typography>

            <PayInsTimeline userPayIns={userPayIns as Required<UserPayIn>[]} />
          </Paper>

        </Grid>

        <Grid item xs={12} md={9}>

          <Grid item xs={12} height={900}>
            <Paper elevation={2} sx={{ p: 2, textAlign: 'center' }} ref={paperGridWidthRefForBarChartByExpenseName as any} >
              <Typography variant="h6" gutterBottom>
                {userExpenses.length ? t('totalExpensesByType') : t('noExpensesYet')}
              </Typography>

              <BarChartByExpenseName byExpenseName={byExpenseName} parentRef={paperGridWidthRefForBarChartByExpenseName} />
            </Paper>
            <Paper elevation={2} sx={{ p: 2, textAlign: 'center', mt: 1 }} ref={paperGridWidthRefForBarChartByExpenseName as any} >
              <Typography variant="h6" gutterBottom>
                {userExpenses.length ? t('totalExpenseByBankAccount') : t('noExpensesYet')}
              </Typography>

              <BarChartByBankAccount byBankAccount={byBankAccount} parentRef={paperGridWidthRefForBarChartByExpenseName} />
            </Paper>

            <Paper elevation={2} sx={{ p: 2, textAlign: 'center', mt: 1 }} ref={paperGridWidthRefForBarChartByExpenseName as any} >
              <Typography variant="h6" gutterBottom>
                {userExpenses.length ? t('payInsAndExpensesByDay') : t('noPayInsAndExpensesYet')}
              </Typography>
              <ChartByPayIns byPayIns={chartByPayInsData} parentRef={paperGridWidthRefForBarChartByExpenseName} />
            </Paper>
          </Grid>

        </Grid>

      </Grid>
    </Container>
  );
};

export default UserExpenseMetrics;
