import AttachMoneySharpIcon from '@mui/icons-material/AttachMoneySharp';
import { Fab } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useQueryUserExpenses } from "../hooks/expenses-hooks";
import { GlobalState } from "../redux/state-slices/globalSlice";
import { StringOrNumber } from "../types/global";
import { getCurrencyVerboseFormatter } from "../util/formatting";

const currency = getCurrencyVerboseFormatter();

export function TotalSpentView() {

  const { t } = useTranslation();
  const dateRange = useSelector<GlobalState, [StringOrNumber, StringOrNumber]>(s => s.global.dateRange);
  const { data: userExpenses } = useQueryUserExpenses(dateRange);

  const [showText, toggleText] = useState<boolean>(false);
  return (
    <Fab color="secondary" variant={showText ? 'extended' : 'circular'} size="medium" sx={{transition: 'all ease-in-out .3s', cursor: 'pointer', position: 'fixed', bottom: '15px', left: '15px'}}
    onClick={() => toggleText(!showText)}
    >
      <AttachMoneySharpIcon fontSize="large"/>
      
      {showText && (<><span>{t('totalSpent')}</span> <strong>{currency.format(userExpenses?.metrics.totalExpensesAmmount || 0).replace('$', '')}</strong></>)}
    </Fab>
  );
}

