import { AppBar, Button, Container, LinearProgress, Paper, Snackbar, Stack, Tab, Tabs, TextField, ThemeOptions, ThemeProvider, Typography, createTheme } from "@mui/material";
import { FC, SyntheticEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import MenuBar from "../components/MenuBar";
import { useMutationLogin, useMutationUserConfirmSignup, useMutationUserSignup, useQueryUserSession } from "../hooks/user-session-hooks";
import { LoginData, SignupData } from "../types/User";
import { dark } from "../config/themeConfig";

const defaultLoginData: LoginData = {
  email: '',
  password: '',
};

const defaultSignupData: SignupData = {
  ...defaultLoginData,
  passwordConfirm: '',
  signupConfirmCode: '',
};

const Login: FC<{}> = () => {
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: userInfo } = useQueryUserSession();
  const [tabIdx, setTabIdx] = useState<number>(0);
  const [loader, showLoader] = useState<boolean>(false);

  const tabChange = (_: SyntheticEvent, newValue: number) => setTabIdx(newValue);
  const [loginData, setLoginData] = useState<LoginData>(defaultLoginData);
  const [signupData, setSignupData] = useState<SignupData>(defaultSignupData);
  const [confirmPasswordStyle, setConfirmPasswordStyle] = useState<string>('');

  const [msg, setMsg] = useState<string | undefined>();

  const userSignupMutation = useMutationUserSignup();
  const userConfirmSignupMutation = useMutationUserConfirmSignup();
  const useLoginMutation = useMutationLogin();

  const [themeConfig, setTheme] = useState<ThemeOptions>(dark);
  const theme = createTheme(themeConfig);

  return (
    <ThemeProvider theme={theme}>
      <Container sx={{ width: '450px', pt: 5 }}>
        {(loader || useLoginMutation.isPending || userSignupMutation.isPending || userConfirmSignupMutation.isPending) && <LinearProgress />}
        <MenuBar showLogout={false} setThemeFn={setTheme} />

        <Paper elevation={6}>
          <AppBar position="static">
            <Tabs value={tabIdx} onChange={tabChange} style={{backgroundColor: theme.palette.grey[100]}}>
              <Tab label={t('login')} id="login-tab" aria-controls="tab-panel-0" />
              <Tab label={t('signup')} id="signup-tab" aria-controls="tab-panel-1" />
              <Tab label={t('confirmSignup')} id="signup-tab" aria-controls="tab-panel-2" />
            </Tabs>
          </AppBar>

          <Paper hidden={tabIdx !== 0} sx={{ p: 5, }} elevation={6}>
            <Stack spacing={1} alignItems="flex-end">
              <TextField value={loginData.email}
                onChange={(e) => setLoginData({ ...loginData, email: e.target.value })}
                type="email" id="login-email-input" label={t('email')} sx={{ width: '100%' }} />

              <TextField value={loginData.password}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') document.getElementById('login-button')?.click();
                }}
                onChange={(e) => setLoginData({ ...loginData, password: e.target.value })}
                type="password" id="login-password-input" label={t('password')} sx={{ width: '100%' }} />

              <Button variant="contained" sx={{ width: '200px' }}
                id="login-button"
                disabled={useLoginMutation.isPending}
                onClick={() => {
                  if (loginData.email === '') {
                    setMsg(t('emailRequired'));
                    return;
                  }
                  if (loginData.password === '') {
                    setMsg(t('passwordRequired'));
                    return;
                  }
                  useLoginMutation.mutateAsync(loginData)
                    .then((e: any) => {
                      setMsg(e.message ?? JSON.stringify(e));
                    })
                    .catch(e => {
                      setMsg(JSON.stringify(e));
                    });
                }}
              >
                {t('login')}
              </Button>
            </Stack>
          </Paper>

          <Paper hidden={tabIdx !== 2} sx={{ p: 5, }} elevation={6}>
            <Stack spacing={1} alignItems="flex-end">
              <Typography variant="caption">
                {t('checkYourEmail')}
              </Typography>

              <TextField value={signupData.signupConfirmCode}
                onChange={(e) => setSignupData({ ...signupData, signupConfirmCode: e.target.value })}
                type="text" id="confirm-code-input" sx={{ width: '100%' }} size="small" />

              <Button variant="contained" sx={{ width: '200px' }}
                disabled={userConfirmSignupMutation.isPending || loader}
                onClick={() => {
                  if (signupData.signupConfirmCode === '') {
                    setMsg(t('confirmCodeRequired'));
                    return;
                  }
                  showLoader(true);
                  userConfirmSignupMutation.mutateAsync({ email: signupData.email, code: signupData.signupConfirmCode })
                    .catch(e => { setMsg(e.message ?? 'Error'); showLoader(false); });
                }}
              >
                {t('confirmSignup')}
              </Button>
            </Stack>
          </Paper>

          <Paper hidden={tabIdx !== 1} sx={{ p: 5, }} elevation={6}>
            <Stack spacing={1} alignItems="flex-end">
              <TextField value={signupData.email}
                onChange={(e) => setSignupData({ ...signupData, email: e.target.value })}
                type="email" id="signup-email-input" label={t('email')} sx={{ width: '100%' }} />

              <TextField value={signupData.password}
                onChange={(e) => setSignupData({ ...signupData, password: e.target.value })}
                type="password" id="signup-password-input" label={t('password')} sx={{ width: '100%' }} />

              <TextField value={signupData.passwordConfirm}
                onChange={(e) => {
                  setSignupData({ ...signupData, passwordConfirm: e.target.value });
                  const style = signupData.password !== e.target.value ? '1px solid red' : '';
                  setConfirmPasswordStyle(style);
                }}
                type="password" id="signup-passwordConfirm-input" label={t('passwordConfirm')} sx={{ width: '100%', border: confirmPasswordStyle }} />

              <Button variant="contained" sx={{ width: '200px' }}
                disabled={userSignupMutation.isPending}
                onClick={() => {
                  const { email, password, passwordConfirm } = signupData;
                  if (!email || email === '') {
                    setMsg(t('emailRequired'));
                    return;
                  }
                  if (!password || password === '') {
                    setMsg(t('passwordRequired'));
                    return;
                  }
                  if (!passwordConfirm || passwordConfirm === '') {
                    setMsg(t('passwordConfirmRequired'));
                    return;
                  }
                  if (password !== passwordConfirm) {
                    setMsg(t('passwordsAreNotEqual'));
                    return;
                  }
                  if (!/[A-Z]+/g.test(password)) {
                    setMsg(t('atLeast1UppercaseChar'));
                    return;
                  }
                  if (!/[0-9]+/g.test(password)) {
                    setMsg(t('atLeast1Number'));
                    return;
                  }
                  // eslint-disable-next-line no-useless-escape
                  if (!/[\^$\*\.\[\]{}()?"!@#%&\/\\,><':;|_~`=+-]+/gm.test(password)) {
                    setMsg(t('atLeast1SpecialChar'));
                    return;
                  }
                  if (password.length < 8) {
                    setMsg(t('atLeast8Chars'));
                    return;
                  }

                  userSignupMutation.mutateAsync(signupData)
                    .then(resp => { if (resp) setTabIdx(2); })
                    .catch(console.log);
                }}
              >
                {t('signup')}
              </Button>
            </Stack>
          </Paper>
        </Paper>

        <Snackbar
          open={msg !== undefined}
          autoHideDuration={25000}
          onClose={() => setMsg(undefined)}
          message={msg}
        />
      </Container>
    </ThemeProvider>
  );
};

export default Login;