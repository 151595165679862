import { Button, LinearProgress, Paper, SxProps, TextField, Theme, Typography } from "@mui/material";
import Snackbar from '@mui/material/Snackbar';
import { useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import { FC, Fragment, useState } from "react";
import { useQueryUserBankAccounts } from "../hooks/bank-hooks";
import { useMutationCreatePayIn } from "../hooks/pay-in-hooks";
import { AxiosResp } from "../rest-api/request-util";
import { UserPayIn } from "../types/User";
import TextFieldHint from "./TextFieldHint";
import dayjs from "dayjs";
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.tz.setDefault("America/Bogota");

const emptyUserPayIn: UserPayIn = {
  accountName: '',
  amount: 0,
  createDate: parseInt(dayjs().format('x')),
  description: '',
};

export type CreatePayInFormProps = {
  closeFormFn: Function;
};
const CreatePayInForm: FC<CreatePayInFormProps> = ({ closeFormFn }) => {
  const [payIn, setPayIn] = useState(emptyUserPayIn);

  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [errorMsg, setShowError] = useState<string | undefined>();

  const queryClient = useQueryClient();
  const createPayInMutation = useMutationCreatePayIn(queryClient);
  const { data: userBankAccounts } = useQueryUserBankAccounts();

  const handleSubmit = (e: any) => {
    e.preventDefault();

    setShowLoader(true);
    createPayInMutation.mutateAsync(payIn)
      .then((e) => {
        setShowLoader(false);
        closeFormFn();
      })
      .catch((resp: AxiosResp | any) => {
        const err = typeof (resp.data) === 'string' ? resp.data : JSON.stringify(resp.data);
        setShowLoader(false);
        setShowError(err);
      });
  };

  return (
    <Fragment>
      {showLoader && <LinearProgress color="secondary" />}

      <Snackbar message={errorMsg} open={errorMsg !== undefined}
        autoHideDuration={3000} onClose={() => setShowError(undefined)}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      />

      <Paper elevation={3} sx={sxPaper()}>
        <Typography variant="h5">{t('createPayIn')}</Typography>
        <form>
          <TextFieldHint inputValue={payIn.accountName}
            setInputValue={(s: string) => setPayIn({ ...payIn, accountName: s })}
            label={t('accountName')} options={(userBankAccounts ?? []).map(b => b.accountName)} required={true} />
          <TextField
            value={payIn.amount}
            onChange={(e) => setPayIn({ ...payIn, amount: parseInt(e.target.value ?? 0) })}
            label={t('payInAmount')}
            variant="outlined"
            margin="normal"
            fullWidth
            required
            type="number"
          />
          <TextField
            value={dayjs(payIn.createDate).format('YYYY-MM-DDTHH:mm:ss')}
            onChange={(e) => setPayIn({ ...payIn, createDate: new Date(e.target.value).getTime() + (Math.ceil(Math.random() * 999)) })}
            variant="outlined"
            margin="normal"
            fullWidth
            type="datetime-local"
            label={t('payInDate')}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            value={payIn.description}
            onChange={(e) => setPayIn({ ...payIn, description: e.target.value })}
            label={t('description')}
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <Button disabled={showLoader} variant="contained" color="primary" type="submit" sx={{ mt: 2 }} onClick={handleSubmit}>
            {t('create')}
          </Button>
        </form>
      </Paper>
    </ Fragment>
  );
};

export default CreatePayInForm;


function sxPaper(): SxProps<Theme> {
  return {
    width: '90%',
    maxWidth: '600px',
    p: 4,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left'
  };
}