import axios from 'axios';


export const financesApiAxios = axios.create({
  baseURL: process.env.REACT_APP_FINANCESAPI,
});

export const filesApiAxios = axios.create({
  baseURL: process.env.REACT_APP_FILESAPI,
});

