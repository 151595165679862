import AttachFileIcon from '@mui/icons-material/AttachFile';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, LinearProgress, Paper, SxProps, TextField, Theme, Typography, useTheme } from "@mui/material";
import Snackbar from '@mui/material/Snackbar';
import { useQueryClient } from "@tanstack/react-query";
import axios from 'axios';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { t } from "i18next";
import { ChangeEvent, FC, Fragment, useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { useQueryUserBankAccounts } from "../hooks/bank-hooks";
import { useMutationCreateUserExpense, useQueryUserExpenseTypes } from "../hooks/expenses-hooks";
import { GlobalState } from '../redux/state-slices/globalSlice';
import { __filesApiClient } from "../rest-api/client-instances-application-scoped";
import { AxiosResp } from "../rest-api/request-util";
import { UserExpenseFriendly } from "../types/User";
import TextFieldHint from "./TextFieldHint";

dayjs.extend(timezone);
dayjs.tz.setDefault("America/Bogota");


const emptyExpense: UserExpenseFriendly = {
  accountName: '',
  expenseName: '',
  amount: '',
  comment: '',
  createDate: dayjs().format('YYYY-MM-DDThh:mm'),
  receiptImageUrl: '',
};

export type CreateExpenseFormProps = {
  closeFormFn: Function;
};

const financesS3BucketBaseUrl = process.env.REACT_APP_FINANCES_BUCKET_URL as string;

const CreateExpenseForm: FC<CreateExpenseFormProps> = ({ closeFormFn }) => {

  const userId = useSelector<GlobalState, string>(s => s.global.userId as string);
  const [expense, setExpense] = useState(emptyExpense);
  const [showLoader, setShowLoader] = useState<boolean>(false);

  const { data: existingExpenseTypes } = useQueryUserExpenseTypes();

  const queryClient = useQueryClient();
  const createUserExpenseMutation = useMutationCreateUserExpense(queryClient);
  const { data: userBankAccounts } = useQueryUserBankAccounts();

  const [errorMsg, setShowError] = useState<string | undefined>();

  const [file, setFile] = useState<File>();

  const uploadImage = async () => {
    if (file) {
      setShowError('Uploading Image');

      const createDateEpoch = new Date(expense.createDate as string).getTime();

      await __filesApiClient.uploadImage({
        fileName: `${encodeURIComponent(userId)}/receipts/expense_${createDateEpoch}__${file.name}`,
        fileType: file.type,
        fileContent: new Blob([file], { type: file.type }) as unknown as Buffer,
        axio: axios,
      });
    } else {
      setShowError('No image attached');
    }
  };

  const handleClickUpload = () => document.getElementById('create-expense-upload-input')?.click();

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (typeof (expense.createDate) === 'string' && expense.createDate.trim() === '') {
      delete expense.createDate;
    }

    if (expense.accountName === '') delete expense.accountName;

    if(expense.amount === '') {
      setShowError(t('amountRequired'));
      return;
    }

    const finalExpense = { ...expense };

    if (file) {
      const createDateEpoch = new Date(expense.createDate as string).getTime();
      finalExpense.receiptImageUrl = `${financesS3BucketBaseUrl}${encodeURIComponent(userId)}/receipts/expense_${createDateEpoch}__${encodeURIComponent(file.name)}`
    }

    setShowLoader(true);
    createUserExpenseMutation.mutateAsync(finalExpense)
      .then(() => {
        uploadImage()
          .then(() => {
            setShowLoader(false);
            closeFormFn();
          })
          .catch((resp: AxiosResp | any) => {
            const err = typeof (resp.data) === 'string' ? resp.data : JSON.stringify(resp.data);
            console.log(resp);

            setShowLoader(false);
            setShowError(err);
          });
      })
      .catch((resp: AxiosResp | any) => {
        setShowLoader(false);
        const err = typeof (resp.data) === 'string' ? resp.data : JSON.stringify(resp.data);
        setShowError(err);
      });
  };

  const handleUpload = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    if (target.files && areValidFiles(target.files, setShowError)) {
      const files = Object.values(target.files);
      if (files.length) {
        setFile(files[0]);
      }
    }
  };

  useEffect(() => {
    const form = document.getElementById('create-expense-upload-form');

    if (!form) throw new Error('create-expense-upload-form not found');

    const dragEvents = ['dragenter', 'dragover', 'dragleave', 'drop'];

    function preventDragDefaults(e: ChangeEvent) {
      e.preventDefault();
      e.stopPropagation();
    }

    dragEvents.forEach(eventName => {
      form.addEventListener(eventName as any, preventDragDefaults, false);
    });

    form.addEventListener('drop', function (e: any) {
      const newFile = e.dataTransfer.files;

      if (!areValidFiles(newFile, setShowError)) {
        e.stopPropagation();
        e.stopImmediatePropagation();
        return;
      }

      setFile(newFile[0]);

      e.stopImmediatePropagation();
    });

  }, []);

  const theme = useTheme();

  return (
    <Fragment>
      {showLoader && <LinearProgress color='secondary' />}

      <Snackbar message={errorMsg} open={errorMsg !== undefined}
        autoHideDuration={3000} onClose={() => setShowError(undefined)}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      />

      <Paper elevation={3} sx={sxPaper()}>
        <Typography variant="h5">{t('createExpense')}</Typography>
        <form>
          <TextFieldHint inputValue={expense.accountName ?? ''}
            setInputValue={(s: string) => setExpense({ ...expense, accountName: s })}
            label={t('accountName')} options={(userBankAccounts ?? []).map(b => b.accountName)} required={false} />
          <TextFieldHint inputValue={expense.expenseName}
            setInputValue={(s: string) => setExpense({ ...expense, expenseName: s })}
            label={t('expenseName')} options={existingExpenseTypes ?? []} required={true} />
          <TextField
            value={expense.amount}
            onChange={(e) => {
              const v = e.target.value;

              if (/^\d+$/.test(v)) {
                setExpense({ ...expense, amount: parseInt(e.target.value) });
              } else {
                setShowError(t('onlyNumbers'));
                setExpense({ ...expense, amount: '' });
              }

            }}
            label={t('expenseAmount')}
            variant="outlined"
            margin="normal"
            fullWidth
            required
            type="number"
          />
          <TextField
            value={expense.createDate}
            onChange={(e) => setExpense({ ...expense, createDate: e.target.value })}
            variant="outlined"
            margin="normal"
            fullWidth
            type="datetime-local"
            label={t('createDate')}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            value={expense.comment}
            onChange={(e) => setExpense({ ...expense, comment: e.target.value })}
            label={t('expenseComment')}
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon color="secondary" />}>
              <ReceiptIcon color='primary' />&nbsp;
              <Typography variant="h6"> {t('attachReceipt')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div id="create-expense-upload-form">
                <Box sx={sxUploadForm(theme)}>
                  <Button id="create-expense-upload-button" variant="contained"
                    sx={sxUploadBtn()}
                    onClick={handleClickUpload}
                    endIcon={<AttachFileIcon />}>
                    {t('attach')}
                  </Button>
                  <br />
                  <Typography variant="caption">
                    {file ? file.name : t('dragNDrop')}
                  </Typography>
                  <input
                    style={{ display: 'none' }}
                    type="file"
                    id="create-expense-upload-input"
                    accept="image/jpeg,image/png,application/pdf"
                    onChange={handleUpload}
                  />
                </Box>
              </div>
            </AccordionDetails>
          </Accordion>

          <Button disabled={showLoader} variant="contained" color="primary" type="submit" sx={{ mt: 2 }}
            onClick={handleSubmit}
          >
            {t('create')}
          </Button>
        </form>
      </Paper>
    </ Fragment>
  );
};

export default CreateExpenseForm;

function sxPaper(): SxProps<Theme> {
  return {
    width: '90%',
    maxWidth: '600px',
    p: 4,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left'
  };
}

function sxUploadForm(theme: Theme): SxProps<Theme> {
  return {
    height: '200px',
    backgroundColor: theme.palette.background.default,
    outline: '3px dotted #414141',
    outlineOffset: '-1px',
    pb: '5px',
    borderRadius: 1,
    textAlign: 'center',
    cursor: 'grabbing',
    transition: 'all ease-in-out .3s',
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    }
  };
}

function sxUploadBtn(): SxProps<Theme> {
  return {
    height: '40px',
    minWidth: '170px',
    mt: '80px'
  };
}


function areValidFiles(ff: FileList | null, setErrMsg: Function) {
  if (!ff) return false;

  const newFile = Object.values(ff)[0];

  const nameChunks = newFile.name.split('.');
  const allowedExtensions = ['PNG', 'png', 'JPG', 'JPEG', 'jpeg', 'jpg', 'pdf'];

  const isInvalidType = !['image/jpeg', 'image/png', 'application/pdf'].includes(newFile.type);
  const isInvalidExtension = !allowedExtensions.includes(nameChunks[nameChunks.length - 1]);
  const nameContainsBashlike = newFile.name.includes('.sh') || newFile.name.includes('.exec') || newFile.name.includes('.js') || newFile.name.includes('.cjs') || newFile.name.includes('.mjs') || newFile.name.includes('.jsx');

  if (isInvalidExtension || isInvalidType || nameContainsBashlike) {
    setErrMsg(t('onlyImages'));
    return false;
  }
  return true;
};
