import { ThemeProvider } from '@emotion/react';
import { Box, CssBaseline, Modal, ThemeOptions, createTheme } from '@mui/material';
import { Amplify } from 'aws-amplify';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BankAccountsView } from './components/BankAccountsView';
import CreateExpenseForm from './components/CreateExpenseForm';
import CreatePayInForm from './components/CreatePayInForm';
import DateRangePicker from './components/DateRangePicker';
import { DateRangeView } from './components/DateRangeView';
import FAB from './components/FAB';
import MenuBar from './components/MenuBar';
import { TotalSpentView } from './components/TotalSpentView';
import UserExpenseMetrics from './components/UserExpenseMetrics';
import { dark } from './config/themeConfig';
import { useQueryUserExpenses } from './hooks/expenses-hooks';
import { useQueryUserPayIns } from './hooks/pay-in-hooks';
import { useQueryUserSession } from './hooks/user-session-hooks';
import { GlobalState, toggleShowCreateExpenseForm, toggleShowCreatePayInForm } from './redux/state-slices/globalSlice';
import { StringOrNumber } from './types/global';

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: process.env.REACT_APP_COGNITO_POOL_ID as string,
      userPoolClientId: process.env.REACT_APP_COGNITO_POOL_CLIENT_ID as string,
      signUpVerificationMethod: 'link',
    }
  }
});

const App: React.FC = () => {
  const [themeConfig, setTheme] = useState<ThemeOptions>(dark);
  const theme = createTheme(themeConfig);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: userInfo } = useQueryUserSession();

  const dispatch = useDispatch();

  const showCreateExpenseForm = useSelector<GlobalState, boolean>(state => state.global.showCreateExpenseForm);
  const showCreatePayInForm = useSelector<GlobalState, boolean>(state => state.global.showCreatePayInForm);
  const dateRange = useSelector<GlobalState, [StringOrNumber, StringOrNumber]>(state => state.global.dateRange);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const userId = useSelector<GlobalState, string | undefined>(s => s.global.userId);

  const { data: userExpenses } = useQueryUserExpenses(dateRange);
  const { data: userPayIns } = useQueryUserPayIns(dateRange);

  return (
    <ThemeProvider theme={theme}>
      <>
        <CssBaseline />
        <MenuBar setThemeFn={setTheme} />

        <DateRangePicker />
        <DateRangeView />
        <TotalSpentView />

        <BankAccountsView />

        {userExpenses?.userId && (
          <>
            <UserExpenseMetrics data={userExpenses} userPayIns={userPayIns} />
          </>
        )}

        <FAB />

        <Modal open={showCreateExpenseForm} onClose={() => dispatch(toggleShowCreateExpenseForm(false))}>
          <Box>
            <CreateExpenseForm closeFormFn={() => dispatch(toggleShowCreateExpenseForm(false))}
            />
          </Box>
        </Modal>

        <Modal open={showCreatePayInForm} onClose={() => dispatch(toggleShowCreatePayInForm(false))}>
          <Box>
            <CreatePayInForm closeFormFn={() => dispatch(toggleShowCreatePayInForm(false))}
            />
          </Box>
        </Modal>
      </>
    </ThemeProvider>
  );
}

export default App;
