import { useTheme } from "@mui/material";
import { FC, MutableRefObject, useEffect, useState } from "react";
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from "recharts";
import { getCurrencyCompactFormatter, getCurrencyVerboseFormatter } from "../../util/formatting";

const currencyCompact = getCurrencyCompactFormatter();
const currencyVerbose = getCurrencyVerboseFormatter();

export type BarChartByExpenseNameProps = {
  byExpenseName: Record<string, number>;
  parentRef: MutableRefObject<HTMLElement | undefined>;
};

const BarChartByExpenseName: FC<BarChartByExpenseNameProps> = (props) => {
  const theme = useTheme();
  const { byExpenseName, parentRef } = props;

  const byExpenseNameBarChartData = Object.keys(byExpenseName)
    .map(k => ({ expenseName: k, amount: byExpenseName[k] }));

  const [chartWidth, setChartWidth] = useState(10);

  useEffect(() => {
    const getParentWidth = () => parentRef.current?.offsetWidth ?? 500;
    const handleParentWidth = () => setChartWidth(getParentWidth());

    handleParentWidth();// for page load
    window.addEventListener('resize', handleParentWidth);

    return () => {
      window.removeEventListener('resize', handleParentWidth);
    }
  }, [parentRef]);


  return (
    <BarChart width={chartWidth - 30} height={350} data={byExpenseNameBarChartData} margin={{ left: 0 }}>
      <CartesianGrid stroke={theme.palette.primary.contrastText} strokeDasharray="2" strokeWidth={0.3} />
      <XAxis dataKey="expenseName" tick={({ x, y, payload }) => {
        return (
          <text key={payload.value} x={x - 25} y={y + 15} style={{ fontSize: '11px', fill: theme.palette.primary.contrastText }}>
            {payload.value}
          </text>
        );
      }} />

      <YAxis dataKey="amount" tick={({ x, y, payload }) => {
        return (
          <text key={payload.value} x={x - 40} y={y} style={{ fontSize: '11px', fill: theme.palette.primary.contrastText }}>
            {currencyCompact.format((payload.value as number))}
          </text>
        );
      }} />
      <Tooltip content={<CustomTooltip />} />
      <Bar dataKey="amount" barSize={20} fill={theme.palette.primary.main} />
    </BarChart>
  );
};


export default BarChartByExpenseName;


function CustomTooltip({ active, payload, label }: any) {
  const theme = useTheme();
  if (active && payload && payload.length) {
    return (
      <div style={{ backgroundColor: theme.palette.grey[100], borderRadius: '3px', padding: 9, textAlign: 'start' }}>
        <p>{`${label}, ${currencyVerbose.format(payload[0].value)}`}</p>
      </div>
    );
  }

  return null;
}