import { Theme, useTheme } from "@mui/material";
import dayjs from "dayjs";
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import { t } from "i18next";
import { MutableRefObject, useEffect, useState } from "react";
import { Bar, CartesianGrid, ComposedChart, Legend, Line, Tooltip, XAxis, YAxis, } from 'recharts';
import { ExpensesAndPayInsChartData } from '../../types/User';
import { getCurrencyCompactFormatter, getCurrencyVerboseFormatter } from "../../util/formatting";

dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.tz.setDefault("America/Bogota");

const currencyCompact = getCurrencyCompactFormatter();
const currencyVerbose = getCurrencyVerboseFormatter();

const toEpoch = (date: string) => parseInt(dayjs(date).format('x'));

export type ChartByPayInsProps = {
  byPayIns: ExpensesAndPayInsChartData[];
  parentRef: MutableRefObject<HTMLElement | undefined>;
};

export function ChartByPayIns(props: ChartByPayInsProps) {
  const theme = useTheme();
  const { byPayIns, parentRef } = props;

  byPayIns.sort((a, b) => toEpoch(a.date) - toEpoch(b.date));

  const [chartWidth, setChartWidth] = useState(10);

  useEffect(() => {
    const getParentWidth = () => parentRef.current?.offsetWidth ?? 500;
    const handleParentWidth = () => setChartWidth(getParentWidth());

    handleParentWidth();// for page load
    window.addEventListener('resize', handleParentWidth);

    return () => {
      window.removeEventListener('resize', handleParentWidth);
    }
  }, [parentRef]);

  return (
    <ComposedChart
      width={chartWidth - 30} height={500}
      data={byPayIns}
      margin={{
        top: 20,
        bottom: 20,
        left: 0,
      }}
    >
      <CartesianGrid stroke={theme.palette.primary.contrastText} strokeDasharray="2" strokeWidth={0.3} />
      <XAxis dataKey="date" tick={({ x, y, payload }) => {
        return (
          <text key={payload.value} x={x - 25} y={y + 15} style={{ fontSize: '11px', fill: theme.palette.primary.contrastText }}>
            {dayjs(payload.value).format('MMM, DD')}
          </text>
        );
      }} />

      <YAxis yAxisId="left" orientation="left" stroke={theme.palette.primary.light} tick={({ x, y, payload }) => yAxisFormat(x, y, payload, theme, -40)} />
      <Tooltip content={<CustomTooltip />} />
      <Legend formatter={renderColorfulLegendText as any} />
      <Bar dataKey="expenseAmount" yAxisId="left" barSize={20} fill={theme.palette.secondary.main} />
      <Line type="monotone" yAxisId="left" dataKey="payInAmount" strokeWidth={2} stroke={theme.palette.primary.light} />
    </ComposedChart>
  );
}
const renderColorfulLegendText = (value: string, entry: any) => {
  const { color } = entry;

  return <span style={{ color }}>{t(value)}</span>;
}

function CustomTooltip({ active, payload, label }: any) {
  const theme = useTheme();
  if (active && payload && payload.length) {
    return (
      <div style={{ backgroundColor: theme.palette.grey[100], borderRadius: '3px', padding: 9, textAlign: 'start' }}>
        <ul>
          {Object.entries(payload[0].payload).map(([k, v], i) => <li key={i}>{t(k)}: {formatToolTipValue(k ,v as number)}</li>)}
        </ul>
      </div>
    );
  }

  return null;
}


function formatToolTipValue(k: string, v: string | number) {
  if (k === "payInAmount" || k === "expenseAmount") {
    return currencyVerbose.format(v as number);
  }
  return v;
}


function yAxisFormat(x: number, y: number, payload: any, theme: Theme, xpos: number) {
  const isDark = theme.palette.mode === 'dark';
  const fillColor = isDark ? theme.palette.grey[50] : theme.palette.grey[900];
  return (
    <text key={payload.value} x={x + xpos} y={y} style={{ fontSize: '11px', fill: fillColor }}>
      {currencyCompact.format((payload.value as number))}
    </text>
  );
}