import MoneyOffRoundedIcon from '@mui/icons-material/MoneyOffRounded';
import SavingsRoundedIcon from '@mui/icons-material/SavingsRounded';
import { Backdrop, useTheme } from '@mui/material';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState, toggleFAB, toggleShowCreateExpenseForm, toggleShowCreatePayInForm } from '../redux/state-slices/globalSlice';

export default function FAB() {
  const dispatch = useDispatch();
  const isFABOpen = useSelector<GlobalState, boolean>(state => state.global.isFABOpen);

  const theme = useTheme();
  const { t } = useTranslation();

  const primaryLight = theme.palette.primary.light;
  const actionSx = { color: primaryLight, fontSize: '40px' };
  const actions: any[] = [
    { name: t('createPayIn'), icon: <SavingsRoundedIcon sx={actionSx} />, openFn: toggleShowCreatePayInForm },
    { name: t('createExpense'), icon: <MoneyOffRoundedIcon sx={actionSx} />, openFn: toggleShowCreateExpenseForm },
  ];

  return (
    <>
      <Backdrop open={isFABOpen} sx={{ background: 'rgba(18,18,18,.8)' }} onClick={() => dispatch(toggleFAB(false))} />
      <SpeedDial
        ariaLabel="Menu"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
        icon={<SpeedDialIcon />}
        onClick={() => dispatch(toggleFAB(null))}
        open={isFABOpen}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen={false}
            onClick={() => dispatch(action.openFn(null))}
          />
        ))}
      </SpeedDial>
    </>
  );
}
