import { ServiceFilesClient } from "@illuxa-sas/service-files-client/lib/client/service-files-client";
import { FinancesApiClient } from "./FinancesApiClient";
import { filesApiAxios } from "./axios-instances";


export const __financesApiClient = new FinancesApiClient();

export const __filesApiClient = new ServiceFilesClient({
  baseUrl: process.env.REACT_APP_FILESAPI as string, 
  axiosInstance: filesApiAxios,
});