import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { Grid, Paper, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { GlobalState } from "../redux/state-slices/globalSlice";
import { StringOrNumber } from "../types/global";


export function DateRangeView() {

  const dateRange = useSelector<GlobalState, [StringOrNumber, StringOrNumber]>(s => s.global.dateRange);

  return (
    <Grid container
      justifyContent="center"
      alignItems="center"
    >
      <Grid item>
        <Paper elevation={1} sx={{p: '20px'}}>
          <Typography variant="h6">
            {new Date(dateRange[0]).toLocaleDateString()}
          </Typography>
        </Paper>
      </Grid>
      <Grid item>
        <DoubleArrowIcon sx={{ml: 2, mr: 2}} color="primary" />
      </Grid>
      <Grid item>
        <Paper elevation={1} sx={{p: '20px'}}>
          <Typography variant="h6">
            {new Date(dateRange[1]).toLocaleDateString()}
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  );
}