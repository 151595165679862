import { AxiosResponse } from 'axios';

export type AsyncAxiosResponseFunction = () => Promise<AxiosResponse<any, any>>;

export type AxiosResp = Pick<AxiosResponse, 'config' | 'status' | 'data'>;

export const axiosRequestHandler = async <T>(reqFn: AsyncAxiosResponseFunction): Promise<T> => {
  try {
    const { data } = await reqFn();

    return data as T;
  } catch (error) {
    const e: any = error;

    throw new Error(JSON.stringify({
      status: e.response?.status ?? 500,
      data: e.response?.data ?? e.message ?? `${e}`,
      config: e.response.config ?? {} as any,
    }));
  }
};