import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import LogoutIcon from '@mui/icons-material/Logout';
import { AppBar, FormControl, IconButton, MenuItem, Select, ThemeOptions, Toolbar, Typography, useTheme } from "@mui/material";
import { FC } from "react";
import { FlagIcon, FlagIconCode } from "react-flag-kit";
import { useTranslation } from "react-i18next";
import { dark, light } from '../config/themeConfig';
import { useMutationLogout } from '../hooks/user-session-hooks';

const LANGS = [
  { value: 'en', code: 'US', label: 'English' },
  { value: 'es', code: 'CO', label: 'Español' },
  // { value: 'fr', code: 'FR' },
];

const THEMES = [
  { val: 'light', icon: LightModeIcon },
  { val: 'dark', icon: DarkModeIcon },
];

export type MenuBarProps = {
  showLogout?: boolean;
  setThemeFn: React.Dispatch<React.SetStateAction<ThemeOptions>>;
};

const themeMap: Record<string, ThemeOptions> = { light, dark };

const MenuBar: FC<MenuBarProps> = (props) => {
  const theme = useTheme();
  const { showLogout = true, setThemeFn } = props;
  const { t, i18n } = useTranslation();

  const changeLanguage = (lang: string) => i18n.changeLanguage(lang);
  const useLogoutMutation = useMutationLogout();

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: theme.palette.secondary.contrastText }}>
          {t('title')}
        </Typography>
        <FormControl sx={{ border: 'none', outline: 'none' }} size="small">
          <Select
            IconComponent={() => null} // Remove the default arrow icon
            defaultValue={"dark"}
            onChange={(e) => setThemeFn(themeMap[e.target.value as string])}
            size="small"
            sx={{ outline: 'none', border: 'none', boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
          >
            {
              THEMES.map(t => (
                <MenuItem value={t.val} key={t.val} >
                  {t.icon && <t.icon color='action' fontSize='small' />}&nbsp;{t.val}
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>
        <FormControl sx={{ border: 'none', outline: 'none' }} size="small">
          <Select
            IconComponent={() => null} // Remove the default arrow icon
            defaultValue={"en"}
            onChange={(e) => changeLanguage(e.target.value)}
            size="small"
            sx={{ outline: 'none', border: 'none', boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
          >
            {
              LANGS.map(l => (
                <MenuItem value={l.value} key={l.value}>
                  <FlagIcon code={l.code as FlagIconCode} size={16} />&nbsp;{l.code}
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>
        {showLogout && (
          <IconButton aria-label={t('logout')} size="small"
            onClick={() => {
              useLogoutMutation.mutateAsync();
            }}
          >
            <LogoutIcon color="secondary" fontSize="inherit" />
          </IconButton>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default MenuBar;