import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { Button, Card, CardActions, CardContent, CardHeader, Divider, Grid, IconButton, LinearProgress, Modal, Table, TableBody, TableCell, TableRow, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserExpense } from '../types/User';
import { getCurrencyVerboseFormatter } from '../util/formatting';
import { useMutationDeleteUserExpense } from '../hooks/expenses-hooks';
import { useQueryClient } from '@tanstack/react-query';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.tz.setDefault("America/Bogota");
const currency = getCurrencyVerboseFormatter();

export type ExpenseTimelineProps = {
  userExpenses: Required<UserExpense>[];
};

export default function ExpenseTimeline(props: ExpenseTimelineProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const [showExpenseDetails, setshowExpenseDetails] = useState<boolean>(false);
  const [selectedExpense, setSelectedExpense] = useState<UserExpense>();
  const queryClient = useQueryClient();
  const useDeleteUserExpenseMutation = useMutationDeleteUserExpense(queryClient);

  return (
    <>
      {useDeleteUserExpenseMutation.isPending && <LinearProgress color="secondary" />}

      <Modal open={showExpenseDetails && typeof (selectedExpense) === 'object'} onClose={() => setshowExpenseDetails(false)}
        sx={{ outline: 'none' }}
      >
        <Grid container justifyContent="center" sx={{ mt: '30px', outline: 'none' }}>
          <Grid item xs={11} md={8} lg={5}>
            <Card>
              <CardHeader
                action={
                  <IconButton onClick={() => setshowExpenseDetails(false)}>
                    <CloseIcon />
                  </IconButton>
                }
                title={selectedExpense?.expenseName}
                subheader={dayjs.utc(selectedExpense?.createDate ?? 0).format('MMM D, YYYY, hh:mm')}
              />
              <CardContent>
                <Table size='small'>
                  <TableBody>
                    {Object.entries(selectedExpense ?? {}).map(([k, v]) => {
                      if (/(receiptImageUrl|userId|expenseName|createDate)/.test(k)) return null;

                      return (
                        <TableRow key={`${k}_${v}`}>
                          <TableCell variant="head">{t(k)}</TableCell>
                          <TableCell>
                            {k === 'amount' ? currency.format(v as number) : v ?? ' - '}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </CardContent>
              <Grid item xs={12} textAlign="center">
                {
                  selectedExpense?.receiptImageUrl?.endsWith('.pdf') ?
                    <embed src={selectedExpense?.receiptImageUrl + '#toolbar=1'} type='application/pdf' style={{ width: '90%', height: '50vh' }} />
                    :
                    <img src={selectedExpense?.receiptImageUrl} style={{ maxHeight: '50vh' }} alt={selectedExpense?.expenseName} />
                }
              </Grid>
              <Divider sx={{ mt: 2 }} />
              <CardActions>
                <Grid container spacing={2} justifyContent={'center'}>
                  {/* <Grid item>
                    <Button size="small" variant='text' color='info'>Download Image</Button>
                  </Grid>
                  <Grid item>
                    <Button size="small" variant='text' color='info'>Generate Pdf</Button>
                  </Grid> */}
                  <Grid item>
                    <Button disabled={useDeleteUserExpenseMutation.isPending} size="small" variant='text' color='error'
                      onClick={() => {
                        if (selectedExpense?.createDate) {
                          useDeleteUserExpenseMutation.mutateAsync(selectedExpense.createDate)
                            .then(r => {
                              setshowExpenseDetails(false);
                            })
                            .catch(console.log);
                        } else {
                          console.log('selectedExpense did not have a `createDate` so could not perform DELETE, see expense obj:', selectedExpense);
                        }
                      }}
                    >
                      Delete
                    </Button>
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
          </Grid>
        </Grid>

      </Modal>
      <Timeline position="left" style={{overflowX:"hidden"}}>

        {props.userExpenses.sort((a, b) => b.createDate - a.createDate).map((e, i) => {
          const date = new Date(e.createDate);
          const dotColor = i === 0 ? theme.palette.primary.light : theme.palette.secondary.main;
          const dotScale = i === 0 ? 'scale(2)' : 'scale(1)';
          return (

            <TimelineItem key={`${e.expenseName}-${i}`} className='timeline-item'
              onClick={() => {
                setSelectedExpense(e);
                setshowExpenseDetails(true);
              }}
            >
              <TimelineOppositeContent color={theme.palette.primary.contrastText} sx={{ fontSize: '10px' }}>
                {e.receiptImageUrl && <AttachFileIcon fontSize='small' />} &nbsp;
                {`${date.toLocaleString('default', { year: 'numeric', month: 'short', day: 'numeric', weekday: 'long' })}`}
              </TimelineOppositeContent>

              <TimelineSeparator>
                <TimelineDot sx={{ bgcolor: dotColor, transform: dotScale }} />
                <TimelineConnector sx={{ bgcolor: theme.palette.primary.light }} />
              </TimelineSeparator>

              <TimelineContent sx={{ color: theme.palette.primary.contrastText, fontSize: '11px', width: '100px', marginRight: 2, textAlign: 'right', padding: 0, textIndent: -20 }}>
                {e.expenseName} {currency.format(e.amount)}
              </TimelineContent>
            </TimelineItem>
          )
        })}
      </Timeline>
    </>
  );
}